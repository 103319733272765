/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: fit-content;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: whitesmoke;
  overflow-y: scroll;
}

$cat0: #ebebeb;
$cat1: #97e800;
$cat2: #ccf600;
$cat3: #ffe200;
$cat4: #ffb600;
$cat5: #ff7c00;
$cat6: #ff4800;

$db-blue: #004171;

$grid-gutter-width: 16px;

.error-snack {
  color: #f1f1f1;
  background-color: rgb(141, 7, 7);
  .mat-simple-snackbar-action {
      color: #bdbdbd;
  }
}
.success-snack {
  color: #f1f1f1;
  background-color: rgb(10, 165, 10);
}

.cdk-global-scrollblock { overflow-y: hidden !important; }

.flex-container {
  padding: 0 $grid-gutter-width $grid-gutter-width * 2;
}

.headline {
  background-color: whitesmoke;
  z-index: 1;
  .flex-container {
    padding: $grid-gutter-width;
  }
}

.mat-dialog-actions[align="space-between"] {
  justify-content: space-between;
}

mat-toolbar.header {
  z-index: 1000;
  position: fixed;
  top: 0;
}

.mat-card {
  border-radius: 0 !important;
}

.mat-cell {
  font-size: 15px;
}

b {
  font-weight: 500;
}

h1 {
  margin: 0 !important;
}

table {
  width: 100%;

  th.mat-header-cell {
    text-align: center;
    font-size: inherit;
    font-weight: 500;
    color: black !important;
    background: #fafafa !important;
  }

  td:first-child {
    text-align: left;
  }

  td {
    text-align: center;
    margin: 0 auto;
    line-height: 1.1;
  }

  tr:hover {
    background-color: whitesmoke;
  }
}

.pit-cell {
  border-radius: 5px;
  height: 40px;
  width: 80px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  &.cat-0 {
    background-color: $cat0;
  }
  &.cat-1 {
    background-color: $cat1;
  }
  &.cat-2 {
    background-color: $cat2;
  }
  &.cat-3 {
    background-color: $cat3;
  }
  &.cat-4 {
    background-color: $cat4;
  }
  &.cat-5 {
    background-color: $cat5;
  }
  &.cat-6 {
    background-color: $cat6;
    color: white;
  }
  &.invalid {
    background-color: $cat0 !important;
    color: black !important;
  }
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.site-offline-badge {
  background: red;
  color: white;
  font-size: 11px;
  padding: 6px;
  margin-right: 5px;
  border-radius: 4px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

@keyframes btn-spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #f1f1f1;
  border-top-color: $db-blue;
  animation: btn-spinner 0.8s linear infinite;
}

.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  z-index: 9999;
  background: white;
  color: #333;
  border-radius: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  pointer-events: none;
  -webkit-transform: translate(20px, 0);
  transform: translate(20px, 0);
  box-shadow: 0px 0px 1px 1px #c8c8c8;
  overflow: hidden;
  min-width: 120px;

  table {
    margin-bottom: 0;
    border-collapse: collapse;

    th {
      text-align: left;
      padding: 5px;
      background: whitesmoke;
    }

    td {
      padding: 5px;
      &:first-child {
        width: 30px;
      }
    }
  }
}

.chartjs-tooltip-close {
  text-align: right;
  font-size: 20px;
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.hon-smileys {
  width: 30px;
  height: 30px;
}

.hon-smileys-tooltip {
  width: 20px;
  height: 20px;
}

$hon-square: 30px;

.hon-center-text {
  position: absolute;
  width: $hon-square;
  height: $hon-square;
  text-align: center;
  line-height: $hon-square;
  bottom: 27%;
  margin-bottom: calc($hon-square / -2);
  left: 50%;
  margin-left: calc($hon-square / -2);
  font-weight: bolder;
}

.hon-canvas {
  width: $hon-square;
  height: $hon-square;
}


.tab-custom-overflow .mat-tab-body-wrapper {
  overflow: visible !important;
}
.tab-custom-overflow .mat-tab-body-active {
  overflow: visible !important;
}
.tab-custom-overflow .mat-tab-body-content {
  overflow: visible !important;
}